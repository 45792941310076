<template>
    <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">Text block</div>
        <div class="bg-gray-200 mb-8 px-4" :class="alignmentClass">
            <h2 class="text-4xl text-black block font-bold">{{ getValue('headline')}}</h2>
            <h4 class="text-xl text-gray-700 block font-bold">{{ getValue('subHeadline')}}</h4>
            <span class="mt-4 pb-8 block" v-html="getValue('content')"></span>
        </div>
    </div>
</template>

<script>
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        computed: {
            alignmentClass() {
                return 'text-' + this.getValue('alignment');
            }
        }
    }
</script>